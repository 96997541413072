import {FC, useState} from 'react';
import axios from 'axios';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import api from '../api/api';
import {useUser} from '../contexts/UserContext';

const renderHeader = (): JSX.Element => {
  return <components.Header goBack={true} />;
};

export const SignUp: FC = (): JSX.Element => {
  const navigate = hooks.useAppNavigate();
  const {setUser} = useUser();
  const [streetNo, setStreetNo] = useState<string>('');
  const [streetName, setStreetName] = useState<string>('');
  const [suburb, setSuburb] = useState<string>('');
  const [estate, setEstate] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [aadharFrontImage, setAadharFrontImage] = useState<File | null>(null);
  const [aadharBackImage, setAadharBackImage] = useState<File | null>(null);
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    aadharNumber: '',
    role: 'User',
    familyMembers: [],
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleFamilyMemberAdd = (event: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    if ((event as React.KeyboardEvent).key === 'Enter' || event.type === 'blur') {
      if (inputValue.trim() && inputValue.trim().length > 1) {
        setFormData({
          ...formData,
          familyMembers: [...formData.familyMembers, inputValue.trim()],
        });
        setInputValue(''); 
        event.preventDefault();
      }
    }
  };

  const removeFamilyMember = (index: any) => {
    setFormData({
      ...formData,
      familyMembers: formData.familyMembers.filter((_, i) => i !== index),
    });
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'front' | 'back'
  ) => {
    const file = event.target.files?.[0];
    if (file && file.size > 5 * 1024 * 1024) { 
      alert('File size should not exceed 5MB');
      return;
    }
  
    if (file) {
      if (type === 'front') {
        setAadharFrontImage(file);
      } else {
        setAadharBackImage(file);
      }
    }
  };

    const handleSignUp = async () => {
      if (formData.password !== formData.confirmPassword) {
        alert('Passwords do not match!');
        return;
      }
    
      const signUpData = new FormData();
    
      Object.entries(formData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          signUpData.append(key, JSON.stringify(value));
        } else {
          signUpData.append(key, value as string);
        }
      });
    
      signUpData.append("streetNo", streetNo);
      signUpData.append("streetName", streetName);
      signUpData.append("suburb", suburb);
      signUpData.append("estate", estate);
    
      if (aadharFrontImage) signUpData.append("aadharFront", aadharFrontImage);
      if (aadharBackImage) signUpData.append("aadharBack", aadharBackImage);
    
      try {
        const response = await api.post("/users/register", signUpData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.status === 201) {
          console.log(response.data);
          navigate("/SignUpAccountCreated");
        }
      } catch (error: any) {
        alert(error.response.data.message);
      }
    };
    

  const renderContent = (): JSX.Element => {
    return (
      <div style={{padding: '50px 20px 20px 20px'}}>
        <components.Line style={{marginBottom: 14}} />
        <h1
          style={{
            margin: 0,
            textAlign: 'center',
            ...theme.fonts.Mulish_700Bold,
            color: theme.colors.mainColor,
            fontSize: 32,
            lineHeight: 1.2,
            textTransform: 'capitalize',
            marginBottom: 40,
          }}
        >
          Sign up
        </h1>
        <custom.InputField
          label='Full Name'
          name='fullName'
          placeholder='Full Name'
          icon={<svg.InputCheckSvg />}
          containerStyle={{marginBottom: 20}}
          value={formData.fullName}
          onChange={handleInputChange}
        />
        <custom.InputField
          label='Username'
          name='userName'
          placeholder='username'
          icon={<svg.InputCheckSvg />}
          containerStyle={{marginBottom: 20}}
          value={formData.userName}
          onChange={handleInputChange}
        />
        <custom.InputField
          label='Email'
          name='email'
          icon={<svg.InputCheckSvg />}
          placeholder='user@mail.com'
          containerStyle={{marginBottom: 20}}
          value={formData.email}
          onChange={handleInputChange}
        />
    <div>
      {renderFileInput('Aadhar Front Image', aadharFrontImage, (e) => handleFileChange(e, 'front'))}
      {renderFileInput('Aadhar Back Image', aadharBackImage, (e) => handleFileChange(e, 'back'))}
    </div>
        
        <div
          style={{
            height: '50px',
            paddingLeft: '30px',
            paddingRight: '20px',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
            border: '1px solid rgb(232, 239, 244)',
            marginBottom: '5px',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-8px',
              left: '20px',
              paddingLeft: '10px',
              paddingRight: '10px',
              borderRadius: '12px',
              backgroundColor: 'rgb(255, 255, 255)',
              fontSize: '12px',
              color: 'rgb(255, 105, 0)',
              textTransform: 'uppercase',
              fontFamily: 'Mulish-SemiBold',
            }}
          >
            Family Members
          </div>

          <input
            type='text'
            value={inputValue}
            placeholder='Type a name and press Enter'
            onChange={e => setInputValue(e.target.value)}
            onKeyDown={handleFamilyMemberAdd}
            onBlur={handleFamilyMemberAdd}
            style={{
              width: '100%',
              height: '100%',
              padding: '0px',
              margin: '0px',
              border: 'none',
              outline: 'none',
              backgroundColor: 'transparent',
              fontSize: '16px',
              color: 'rgb(25, 51, 100)',
            }}
          />

          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='12'
              height='12'
              fill='none'
            >
              <g clipPath='url(#a)'>
                <path
                  stroke='#193364'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='0.925'
                  d='m10.05 2.962-5.569 5.57L1.949 6'
                ></path>
              </g>
              <defs>
                <clipPath id='a'>
                  <path fill='#fff' d='M.683 2.202h10.633v7.595H.683z'></path>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '20px'}}>
          {formData.familyMembers.map((member, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px 10px',
                margin: '5px',
                backgroundColor: '#f0f0f0',
                borderRadius: '15px',
                color: '#193364',
                fontSize: '14px',
              }}
            >
              {member}
              <button
                style={{
                  marginLeft: '8px',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => removeFamilyMember(index)}
              >
                ✕
              </button>
            </div>
          ))}
        </div>
        {/* <h3 style={{ color: theme.colors.darkBlue, fontSize: '18px' }}>Address</h3> */}
        <custom.InputField
          name='StreetNo'
          value={streetNo}
          type='Number'
          label='Pincode'
          placeholder='Pincode'
          onChange={e => setStreetNo(e.target.value)}
          containerStyle={{marginBottom: '10px'}}
        />
        <custom.InputField
          name='Street Name'
          value={streetName}
          label='House No., Building Name, Landmark'
          placeholder='House No., Building Name, Landmark'
          onChange={e => setStreetName(e.target.value)}
          containerStyle={{marginBottom: '10px'}}
        />
        <custom.InputField
          name='Suburb'
          value={suburb}
          label='City'
          placeholder='City'
          onChange={e => setSuburb(e.target.value)}
          containerStyle={{marginBottom: '10px'}}
        />
        <custom.InputField
          name='Estate'
          value={estate}
          label='State'
          placeholder='State'
          onChange={e => setEstate(e.target.value)}
          containerStyle={{marginBottom: '20px'}}
        />
        <custom.InputField
          clickable={true}
          label='Password'
          name='password'
          placeholder='Password'
          type={showPassword ? 'text' : 'password'}
          icon={
            <span
              onClick={() => setShowPassword(!showPassword)}
              style={{cursor: 'pointer'}}
            >
              {showPassword ? <svg.EyeSvg /> : <svg.EyeOffSvg />}
            </span>
          }
          containerStyle={{marginBottom: 20}}
          value={formData.password}
          onChange={handleInputChange}
        />

        <custom.InputField
          clickable={true}
          label='Confirm Password'
          name='confirmPassword'
          placeholder='Confirm Password'
          type={showConfirmPassword ? 'text' : 'password'}
          icon={
            <span
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{cursor: 'pointer'}}
            >
              {showConfirmPassword ? <svg.EyeSvg /> : <svg.EyeOffSvg />}
            </span>
          }
          containerStyle={{marginBottom: 20}}
          value={formData.confirmPassword}
          onChange={handleInputChange}
        />
        <custom.InputField
          label='Role'
          name='role'
          type='Select'
          value={formData.role}
          onChange={handleInputChange}
          containerStyle={{marginBottom: 20}}
          options={['User', 'Trustee', 'Supplier']}
        />

        <components.Button
          title='Sign up'
          onClick={handleSignUp}
          style={{marginBottom: 20}}
        />
        <div
          style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}
        >
          <span
            style={{
              marginRight: 4,
              ...theme.fonts.Mulish_400Regular,
              color: theme.colors.textColor,
              fontSize: 16,
              lineHeight: 1.7,
            }}
          >
            Already have an account?
          </span>
          <span
            style={{
              ...theme.fonts.Mulish_400Regular,
              color: theme.colors.mainColor,
              fontSize: 16,
              lineHeight: 1.7,
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/');
            }}
          >
            Sign in.
          </span>
        </div>
      </div>
    );
  };


  const renderFileInput = (label: string, file: File | null, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
      }}
    >
      <label style={{ color: theme.colors.textColor, marginBottom: '8px', padding:'0 30px' }}>
        {label}
      </label>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px 30px',
          border: '1px solid rgb(232, 239, 244)',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
      >
        <input
          type="file"
          accept="image/*"
          onChange={onChange}
          style={{
            display: 'none',
          }}
          id={label}
        />
        <label
          htmlFor={label}
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '14px',
            color: '#007BFF',
            marginRight: '10px',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
            style={{ marginRight: '5px' }}
          >
            <path d="M.5 3.5a.5.5 0 0 1 .5-.5h2.086a.5.5 0 0 1 .354.146L5.5 5.793 8.146 3.146a.5.5 0 0 1 .708 0l6.5 6.5a.5.5 0 0 1 .146.354V12a.5.5 0 0 1-.5.5h-1.086a.5.5 0 0 1-.354-.146l-4.853-4.854L5.354 12.5a.5.5 0 0 1-.708 0l-2-2L.854 12.354a.5.5 0 0 1-.354.146H.5a.5.5 0 0 1-.5-.5V3.5zm2.5 9a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1 0-1h.5a.5.5 0 0 1 .5.5zM5 14a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h.5A.5.5 0 0 1 5 14zM8 14a.5.5 0 0 1-.5.5H7a.5.5 0 0 1 0-1h.5a.5.5 0 0 1 .5.5zM11 14a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1 0-1h.5a.5.5 0 0 1 .5.5z" />
          </svg>
          Choose File
        </label>
        <span
          style={{
            fontSize: '14px',
            color: '#666',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '150px',
          }}
        >
          {file ? file.name : 'No file selected'}
        </span>
      </div>
    </div>
  );

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};

interface FormData {
  fullName: string;
  userName: string;
  email: string;
  password: string;
  confirmPassword: string;
  aadharNumber: string;
  role: string,
  familyMembers: string[];
}
