import axios from 'axios';
import { useEffect, useState, FC } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import {theme} from '../../constants';
import {components} from '../../components';
import { useUser } from '../../contexts/UserContext';
import api from '../../api/api';

export const Order: FC = () => {
  const [loading, setLoading] = useState(false);
  const [ordersData, setOrdersData] = useState<any[]>([]);
  const { user } = useUser();
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user && user._id) {
      setUserId(user._id);
    } else {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      getOrders();
    }
  }, [userId]);

  const getOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/prescription/${userId}`);
      setOrdersData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const renderHeader = () => {
    return <components.Header goBack={true} title="Order history" />;
  };

  const renderContent = (): JSX.Element => {
    if (loading) return <components.Loader />;

    return (
      <div style={{ padding: '10px 0 20px 0' }}>
        <Accordion.Root type="single" collapsible={true}>
          {ordersData.length > 0 ? ordersData.map((order, index) => {
            const orderId = `#${order._id.slice(-6)}`; // Get the last 5 digits of the order ID
            const orderStatusColor =
              order.status.toLowerCase() === 'delivered'
                ? '#51BA74'
                : order.status.toLowerCase() === 'pending' || order.status.toLowerCase() === 'verified'
                ? '#F5C102'
                : '#FF4343'; // Assign colors based on status

            return (
              <Accordion.Item key={index} value={order._id}>
                <Accordion.Trigger
                  style={{
                    flexDirection: 'column',
                    width: '100%',
                    display: 'flex',
                    padding: '10px 20px 18px 20px',
                    borderBottom: '4px solid #E8EFF4',
                  }}
                >
                  <div
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: 7,
                    }}
                  >
                    <h5
                      style={{
                        margin: 0,
                        ...theme.fonts.Mulish_600SemiBold,
                        fontSize: 16,
                        color: theme.colors.mainColor,
                        lineHeight: 1.2,
                      }}
                    >
                      {orderId}
                    </h5>
                    <span
                      style={{
                        ...theme.fonts.Mulish_400Regular,
                        fontSize: 14,
                        textTransform: 'capitalize',
                        lineHeight: 1.5,
                        color: orderStatusColor,
                      }}
                    >
                      {order.status}
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        ...theme.fonts.Mulish_400Regular,
                        fontSize: 12,
                        color: theme.colors.textColor,
                        lineHeight: 1.5,
                      }}
                    >
                      {new Date(order.createdAt).toLocaleString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </span>
                    <span
                      style={{
                        ...theme.fonts.Mulish_700Bold,
                        fontSize: 12,
                        color: theme.colors.mainColor,
                        lineHeight: 1.5,
                      }}
                    >
                     For: {order.forWho}
                    </span>
                  </div>
                </Accordion.Trigger>
                <Accordion.Content
                  style={{
                    backgroundColor: '#F8FAFC',
                    borderBottom: '4px solid #E8EFF4',
                    padding: '10px 20px 18px 20px',
                  }}
                >
                  <div style={{ marginBottom: '10px' }}>
                    <strong>Note: </strong>
                    <span>{order.note}</span>
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    <strong>Address: </strong>
                    <span>{order.address.streetNo} {order.address.streetName}</span>
                    <span>{order.address.suburb}, {order.address.estate}</span>
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    <strong>Prescription Image:</strong>
                    <img src={order.prescriptionImage} alt='Prescription' width='80%'  style={{objectFit:'cover'}}/>
                  </div>
                </Accordion.Content>
              </Accordion.Item>
            );
          }): (
            <div style={{padding: '50px 20px 20px 20px'}}>
            <img
              src={require('../../assets/icons/08.png')}
              alt='order successful'
              style={{
                width: 225.18,
                display: 'block',
                margin: '0 auto',
                marginBottom: 14,
              }}
            />
            <h2
              style={{
                margin: 0,
                textAlign: 'center',
                ...theme.fonts.Mulish_700Bold,
                fontSize: 22,
                lineHeight: 1.2,
                color: theme.colors.mainColor,
                marginBottom: 14,
                textTransform: 'capitalize',
                whiteSpace: 'pre-line',
              }}
            >
              No Orders Available!
            </h2>

          </div>
          )}
        </Accordion.Root>
      </div>
    );
  };
  const renderBottomTabBar = (): JSX.Element | null => {
    return <components.BottomTabBar />;
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {renderBottomTabBar()}
    </>
  );
};
