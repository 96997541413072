import React, {useState} from 'react';

import {hooks} from '../hooks';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import api from '../api/api';

export const ForgotPassword: React.FC = () => {
  const navigate = hooks.useAppNavigate();
  const [email, setEmail] = useState('');

  const sendEmail = async () => {
    try {
      const response = await api.post(`/users/forgotPassword/`, {email});
      alert(response.data.message);
      navigate('/ForgotPasswordSentEmail');

    } catch (error: any) {
      alert(error.response.data.message)
      console.error(error.response.data);
    }
  };

  const renderHeader = () => {
    return <components.Header title='Forgot password' goBack={true} />;
  };

  const renderContent = () => {
    return (
      <div style={{padding: '30px 20px 20px 20px'}}>
        <p
          style={{
            margin: 0,
            marginBottom: 40,
            color: theme.colors.textColor,
            lineHeight: 1.7,
            marginRight: 20,
            ...theme.fonts.Mulish_400Regular,
          }}
        >
          Please enter your email address. You will receive the password reset
          link.
        </p>
        <custom.InputField
          name='email'
          label='Email'
          icon={<svg.InputCheckSvg />}
          placeholder='zenithsneaks@mail.com'
          containerStyle={{marginBottom: 20}}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <components.Button
          title='send'
          onClick={() => {
            sendEmail();
          }}
        />
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
