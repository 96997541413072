import {UserProvider} from './contexts/UserContext';
import {StackNavigator} from './navigation/StackNavigator';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className='container' style={{height: '100vh'}}>
      <UserProvider>
        <StackNavigator />
      </UserProvider>
    </div>
  );
}

export default App;
