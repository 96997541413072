import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute'; // Adjust the path as needed

import { screens } from '../screens';
import { TabNavigator } from './TabNavigator';

const stack = createBrowserRouter([
  {
    path: '/',
    element: <screens.SignIn />,
  },
  {
    path: '/SignUp',
    element: <screens.SignUp />,
  },
  {
    path: '/TabNavigator',
    element: (
      <ProtectedRoute>
        <TabNavigator />
      </ProtectedRoute>
    ),
  },
  {
    path: '/EditProfile',
    element: (
      <ProtectedRoute>
        <screens.EditProfile />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Product',
    element: (
      <ProtectedRoute>
        <screens.Product />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Shop',
    element: (
      <ProtectedRoute>
        <screens.Shop />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Reviews',
    element: (
      <ProtectedRoute>
        <screens.Reviews />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Description',
    element: (
      <ProtectedRoute>
        <screens.Description />
      </ProtectedRoute>
    ),
  },
  {
    path: '/OrderHistory',
    element: (
      <ProtectedRoute>
        <screens.OrderHistory />
      </ProtectedRoute>
    ),
  },
  {
    path: '/NewUsers',
    element: (
      <ProtectedRoute>
        <screens.NewUsers />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Checkout',
    element: (
      <ProtectedRoute>
        <screens.Checkout />
      </ProtectedRoute>
    ),
  },
  {
    path: '/OrderSuccessful',
    element: (
      <ProtectedRoute>
        <screens.OrderSuccessful />
      </ProtectedRoute>
    ),
  },
  {
    path: '/OrderFailed',
    element: (
      <ProtectedRoute>
        <screens.OrderFailed />
      </ProtectedRoute>
    ),
  },
  {
    path: '/SignOut',
    element: (
      <ProtectedRoute>
        <screens.SignOut />
      </ProtectedRoute>
    ),
  },
  {
    path: '/SendPhoneOtp',
    element: (
      <ProtectedRoute>
        <screens.SendPhoneOtp />
      </ProtectedRoute>
    ),
  },
  {
    path: '/VerifyEmail',
    element: (
      <ProtectedRoute>
        <screens.VerifyEmail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/VerifyPhone',
    element: (
      <ProtectedRoute>
        <screens.VerifyPhone />
      </ProtectedRoute>
    ),
  },
  {
    path: '/SendEmailOtp',
    element: (
      <ProtectedRoute>
        <screens.SendEmailOtp />
      </ProtectedRoute>
    ),
  },
  {
    path: '/OrderHistoryEmpty',
    element: (
      <ProtectedRoute>
        <screens.OrderHistoryEmpty />
      </ProtectedRoute>
    ),
  },
  {
    path: '/EmailVerified',
    element: (
      <ProtectedRoute>
        <screens.EmailVerified />
      </ProtectedRoute>
    ),
  },
  {
    path: '/PhoneVerified',
    element: (
      <ProtectedRoute>
        <screens.PhoneVerified />
      </ProtectedRoute>
    ),
  },
  {
    path: '/SignUpAccountCreated',
    element: (
      <ProtectedRoute>
        <screens.SignUpAccountCreated />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ForgotPassword',
    element: (
      <ProtectedRoute>
        <screens.ForgotPassword />
      </ProtectedRoute>
    ),
  },
  {
    path: '/NewPassword',
    element: (
      <ProtectedRoute>
        <screens.NewPassword />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ForgotPasswordSentEmail',
    element: (
      <ProtectedRoute>
        <screens.ForgotPasswordSentEmail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/DeleteAccount',
    element: (
      <ProtectedRoute>
        <screens.DeleteAccount />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ShippingAndPaymentInfo',
    element: (
      <ProtectedRoute>
        <screens.ShippingAndPaymentInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: '/InfoSaved',
    element: (
      <ProtectedRoute>
        <screens.InfoSaved />
      </ProtectedRoute>
    ),
  },
  {
    path: '/LeaveAReview',
    element: (
      <ProtectedRoute>
        <screens.LeaveAReview />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Filter',
    element: (
      <ProtectedRoute>
        <screens.Filter />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Prescription',
    element: (
      <ProtectedRoute>
        <screens.Prescription />
      </ProtectedRoute>
    )
  },
  {
    path: '/Quotations',
    element: (
      <ProtectedRoute>
        <screens.Quotation />
      </ProtectedRoute>
    )
  },
]);

export const StackNavigator: React.FC = () => {
  return <RouterProvider router={stack} />;
};
