import * as React from 'react';
import {SVGProps} from 'react';

type Props = SVGProps<SVGSVGElement>;

export const HomeTabSvg: React.FC<Props> = ({color = '#F5C102'}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
      <g>
        <path
          fill={color}
          d='m23.4 10.392-.002-.002L13.608.6a2.194 2.194 0 0 0-1.562-.647c-.59 0-1.145.23-1.563.647L.698 10.385a2.212 2.212 0 0 0-.006 3.13 2.197 2.197 0 0 0 1.535.648h.39v7.204a2.589 2.589 0 0 0 2.586 2.586h3.83a.703.703 0 0 0 .703-.703v-5.648c0-.651.53-1.18 1.18-1.18h2.26c.65 0 1.18.529 1.18 1.18v5.648c0 .388.314.703.702.703h3.83a2.589 2.589 0 0 0 2.586-2.586v-7.204h.362c.59 0 1.145-.23 1.563-.648.86-.86.86-2.261.001-3.123Zm-.996 2.13a.798.798 0 0 1-.568.235h-1.065a.703.703 0 0 0-.703.703v7.907c0 .65-.529 1.18-1.18 1.18h-3.127v-4.945a2.589 2.589 0 0 0-2.586-2.586h-2.259a2.59 2.59 0 0 0-2.586 2.586v4.945H5.203c-.65 0-1.18-.53-1.18-1.18V13.46a.703.703 0 0 0-.703-.703H2.273a.797.797 0 0 1-.586-.236.804.804 0 0 1 0-1.136h.001l9.79-9.79a.797.797 0 0 1 .568-.236c.214 0 .416.084.568.236l9.787 9.787a.805.805 0 0 1 .003 1.14Z'
        />
      </g>
      <defs>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </defs>
    </svg>
  );
};
