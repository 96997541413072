import React, { useEffect, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const allUsers = localStorage.getItem('user');
    const allowedRoutes = ['/ForgotPassword','/NewPassword','/ForgotPasswordSentEmail', '/SignUpAccountCreated']; 

    if (!allUsers && !allowedRoutes.includes(location.pathname)) {
      navigate('/');
    }
  }, [navigate, location]);

  return <>{children}</>;
};

export default ProtectedRoute;
