import {FC, useState, useEffect} from 'react';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {components} from '../components';
import {useUser} from '../contexts/UserContext';
import api from '../api/api';
import {theme} from '../constants';

export const EditProfile: FC = (): JSX.Element => {
  const {user, setUser} = useUser();
  const navigate = hooks.useAppNavigate();

  const [streetNo, setStreetNo] = useState<string>(
    user?.address?.streetNo || '',
  );
  const [streetName, setStreetName] = useState<string>(
    user?.address?.streetName || '',
  );
  const [suburb, setSuburb] = useState<string>(user?.address?.suburb || '');
  const [estate, setEstate] = useState<string>(user?.address?.estate || '');
  const [inputValue, setInputValue] = useState('');
  const [aadharFrontImage, setAadharFrontImage] = useState<File | null>(null);
  const [aadharBackImage, setAadharBackImage] = useState<File | null>(null);
  const [aadharFrontImageUrl, setAadharFrontImageUrl] = useState<string>(
    user?.aadharFrontImage || '',
  );
  const [aadharBackImageUrl, setAadharBackImageUrl] = useState<string>(
    user?.aadharBackImage || '',
  );
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditMode = () => setIsEditing(!isEditing);

  const [formData, setFormData] = useState<{
    fullName: string;
    userName: string;
    email: string;
    familyMembers: string[];
  }>({
    fullName: user?.fullName || '',
    userName: user?.userName || '',
    email: user?.email || '',
    familyMembers: user?.familyMembers || [],
  });

  useEffect(() => {
    if (user) {
      setFormData({
        fullName: user.fullName,
        userName: user.userName,
        email: user.email,
        familyMembers: user.familyMembers,
      });
      setStreetNo(user?.address?.streetNo || '');
      setStreetName(user?.address?.streetName || '');
      setSuburb(user?.address?.suburb || '');
      setEstate(user?.address?.estate || '');
      setAadharFrontImageUrl(user?.aadharFrontImage || '');
      setAadharBackImageUrl(user?.aadharBackImage || '');
    }
  }, [user]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleFamilyMemberAdd = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>,
  ) => {
    if (
      (event as React.KeyboardEvent).key === 'Enter' ||
      event.type === 'blur'
    ) {
      if (inputValue.trim() && inputValue.trim().length > 1) {
        setFormData({
          ...formData,
          familyMembers: [...formData.familyMembers, inputValue.trim()],
        });
        setInputValue('');
        event.preventDefault();
      }
    }
  };

  const removeFamilyMember = (index: number) => {
    setFormData({
      ...formData,
      familyMembers: formData.familyMembers.filter((_, i) => i !== index),
    });
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'front' | 'back',
  ) => {
    const file = event.target.files?.[0];
    if (file && file.size > 500 * 1024) {
      alert('File size should not exceed 500KB');
      return;
    }

    if (file) {
      if (type === 'front') setAadharFrontImage(file);
      else setAadharBackImage(file);
    }
  };

  const handleSaveChanges = async () => {
    const formDataToSend = new FormData();

    formDataToSend.append('userId', user?._id || '');
    formDataToSend.append('fullName', formData.fullName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('streetNo', streetNo);
    formDataToSend.append('streetName', streetName);
    formDataToSend.append('suburb', suburb);
    formDataToSend.append('estate', estate);
    formDataToSend.append(
      'familyMembers',
      JSON.stringify(formData.familyMembers),
    );

    if (aadharFrontImage)
      formDataToSend.append('aadharFront', aadharFrontImage);
    if (aadharBackImage) formDataToSend.append('aadharBack', aadharBackImage);

    try {
      const response = await api.put('/users/edit-profile', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user ? user.token : null}`,
        },
      });
      if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify(response.data));
        setUser(response.data);
        navigate('/InfoSaved');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const renderContent = () => (
    <div style={{padding: '50px 20px 20px 20px'}}>
      <custom.InputField
        label='Username'
        name='userName'
        value={formData.userName}
        placeholder='Username'
        onChange={() => {}}
        containerStyle={{marginBottom: 20, pointerEvents: 'none', opacity: 0.6, border: isEditing ? '1px solid rgb(232, 239, 244)' : 'none',}}
      />
      <custom.InputField
        label='Full Name'
        name='fullName'
        placeholder='Enter full name'
        containerStyle={{
          marginBottom: 20,
          pointerEvents: isEditing ? 'auto' : 'none',
          opacity: isEditing ? 1 : 0.6,
          border: isEditing ? '1px solid rgb(232, 239, 244)' : 'none',
        }}
        value={formData.fullName}
        onChange={handleInputChange}
      />
      <custom.InputField
        label='Email'
        name='email'
        placeholder='Enter email'
        containerStyle={{
          marginBottom: 20,
          pointerEvents: isEditing ? 'auto' : 'none',
          opacity: isEditing ? 1 : 0.6,
          border: isEditing ? '1px solid rgb(232, 239, 244)' : 'none',
        }}
        value={formData.email}
        onChange={handleInputChange}
      />
      <div>
        {renderFileInput(
          'Aadhar Front Image',
          aadharFrontImageUrl,
          e => handleFileChange(e, 'front'),
          isEditing,
        )}
        {renderFileInput(
          'Aadhar Back Image',
          aadharBackImageUrl,
          e => handleFileChange(e, 'back'),
          isEditing,
        )}
      </div>

      <div
        style={{
          height: isEditing? '50px' : '10px',
          paddingLeft: '30px',
          paddingRight: '20px',
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
          border: isEditing ? '1px solid rgb(232, 239, 244)' : 'none',
          marginBottom: '5px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '-8px',
            left: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '12px',
            backgroundColor: 'rgb(255, 255, 255)',
            fontSize: '12px',
            color: 'rgb(255, 105, 0)',
            textTransform: 'uppercase',
            fontFamily: 'Mulish-SemiBold',
          }}
        >
          Family Members
        </div>

        <input
          type='text'
          value={inputValue}
          placeholder='Type a name and press Enter'
          onChange={e => setInputValue(e.target.value)}
          onKeyDown={handleFamilyMemberAdd}
          onBlur={handleFamilyMemberAdd}
          readOnly={!isEditing}
          style={{
            width: '100%',
            height: '100%',
            padding: '0px',
            margin: '0px',
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            fontSize: '16px',
            color: 'rgb(25, 51, 100)',
            pointerEvents: isEditing ? 'auto' : 'none',
            opacity: isEditing ? 1 : 0,
          }}
        />

      </div>

      <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '20px'}}>
        {formData.familyMembers.map((member, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px 10px',
              margin: '5px',
              backgroundColor: '#f0f0f0',
              borderRadius: '15px',
              color: '#193364',
              fontSize: '14px',
              opacity: isEditing ? '1' : '0.6'
            }}
          >
            {member}
            <button
              style={{
                marginLeft: '8px',
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                pointerEvents: isEditing ? 'auto' : 'none',
                opacity: isEditing ? 1 : 0.6,
              }}
              onClick={() => removeFamilyMember(index)}
            >
              ✕
            </button>
          </div>
        ))}
      </div>
      <custom.InputField
        name='StreetNo'
        value={streetNo}
        label='Pincode'
        placeholder='Enter Pincode'
        containerStyle={{
          marginBottom: 20,
          pointerEvents: isEditing ? 'auto' : 'none',
          opacity: isEditing ? 1 : 0.6,
          border: isEditing ? '1px solid rgb(232, 239, 244)' : 'none',
        }}
        onChange={e => setStreetNo(e.target.value)}
      />
      <custom.InputField
        name='Street Name'
        value={streetName}
        label='House No., Building Name, Landmark'
        placeholder='House No., Building Name, Landmark'
        containerStyle={{
          marginBottom: 20,
          pointerEvents: isEditing ? 'auto' : 'none',
          opacity: isEditing ? 1 : 0.6,
          border: isEditing ? '1px solid rgb(232, 239, 244)' : 'none',
        }}
        onChange={e => setStreetName(e.target.value)}
      />
      <custom.InputField
        name='Suburb'
        value={suburb}
        label='City'
        placeholder='City'
        containerStyle={{
          marginBottom: 20,
          pointerEvents: isEditing ? 'auto' : 'none',
          opacity: isEditing ? 1 : 0.6,
          border: isEditing ? '1px solid rgb(232, 239, 244)' : 'none',
        }}
        onChange={e => setSuburb(e.target.value)}
      />
      <custom.InputField
        name='Estate'
        value={estate}
        label='State'
        placeholder='State'
        containerStyle={{
          marginBottom: 20,
          pointerEvents: isEditing ? 'auto' : 'none',
          opacity: isEditing ? 1 : 0.6,
          border: isEditing ? '1px solid rgb(232, 239, 244)' : 'none',
        }}
        onChange={e => setEstate(e.target.value)}
      />
      {/* <components.Button title='Save Changes' onClick={handleSaveChanges} /> */}
    </div>
  );

  const renderFileInput = (
    label: string,
    file: any,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    isEditing: boolean,
  ) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
      }}
    >
      <label
        style={{
          color: theme.colors.textColor,
          marginBottom: '8px',
          padding: '0 30px',
        }}
      >
        {label}
      </label>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <img src={file} alt='aadhar' width={'50px'} />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 30px',
            border: '1px solid rgb(232, 239, 244)',
            borderRadius: '8px',
            cursor: isEditing ? 'pointer' : 'not-allowed',  
            opacity: isEditing ? 1 : 0, 
            pointerEvents: isEditing ? 'auto' : 'none',  
          }}
        >
          <input
            type='file'
            accept='image/*'
            onChange={onChange}
            style={{
              display: 'none',
            }}
            id={label}
          />
          <label
            htmlFor={label}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: isEditing ? 'pointer' : 'not-allowed',  
              fontSize: '14px',
              color: '#007BFF',
              marginRight: '10px',
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              viewBox='0 0 16 16'
              style={{marginRight: '5px'}}
            >
              <path d='M.5 3.5a.5.5 0 0 1 .5-.5h2.086a.5.5 0 0 1 .354.146L5.5 5.793 8.146 3.146a.5.5 0 0 1 .708 0l6.5 6.5a.5.5 0 0 1 .146.354V12a.5.5 0 0 1-.5.5h-1.086a.5.5 0 0 1-.354-.146l-4.853-4.854L5.354 12.5a.5.5 0 0 1-.708 0l-2-2L.854 12.354a.5.5 0 0 1-.354.146H.5a.5.5 0 0 1-.5-.5V3.5zm2.5 9a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1 0-1h.5a.5.5 0 0 1 .5.5zM5 14a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h.5A.5.5 0 0 1 5 14zM8 14a.5.5 0 0 1-.5.5H7a.5.5 0 0 1 0-1h.5a.5.5 0 0 1 .5.5zM11 14a.5.5 0 0 1-.5.5h-.5a.5.5 0 0 1 0-1h.5a.5.5 0 0 1 .5.5z' />
            </svg>
            Choose File
          </label>
          <span
            style={{
              fontSize: '14px',
              color: '#666',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '150px',
            }}
          >
            {file ? file.name : 'No file selected'}
          </span>
        </div>
      </div>
    </div>
  );

  const renderHeader = (): JSX.Element => {
    return (
      <>
        <components.Header goBack={true} title='Edit Profile' />
        <div id='editButton'>
          {isEditing ? (
            <components.Button title='Save' onClick={handleSaveChanges} />
          ) : (
            <components.Button title='Edit' onClick={toggleEditMode} />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
