// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Mulish-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Mulish-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Mulish-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Mulish-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Mulish-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Mulish-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Mulish-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
  font-family: 'Mulish-Black';
  font-style: normal;
  font-weight: 700;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

@font-face {
  font-family: 'Mulish-Bold';
  font-style: normal;
  font-weight: 700;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

@font-face {
  font-family: 'Mulish-ExtraBold';
  font-style: normal;
  font-weight: 800;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

@font-face {
  font-family: 'Mulish-ExtraLight';
  font-style: normal;
  font-weight: 200;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}

@font-face {
  font-family: 'Mulish-Light';
  font-style: normal;
  font-weight: 300;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
}

@font-face {
  font-family: 'Mulish-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
}

@font-face {
  font-family: 'Mulish-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype');
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":";AACA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,+DAAiD;AACnD;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,+DAAgD;AAClD;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,gBAAgB;EAChB,+DAAqD;AACvD;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,+DAAsD;AACxD;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,+DAAiD;AACnD;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,+DAAmD;AACrD;;AAEA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;EAChB,+DAAoD;AACtD","sourcesContent":["\n@font-face {\n  font-family: 'Mulish-Black';\n  font-style: normal;\n  font-weight: 700;\n  src: url('./Mulish-Black.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Mulish-Bold';\n  font-style: normal;\n  font-weight: 700;\n  src: url('./Mulish-Bold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Mulish-ExtraBold';\n  font-style: normal;\n  font-weight: 800;\n  src: url('./Mulish-ExtraBold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Mulish-ExtraLight';\n  font-style: normal;\n  font-weight: 200;\n  src: url('./Mulish-ExtraLight.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Mulish-Light';\n  font-style: normal;\n  font-weight: 300;\n  src: url('./Mulish-Light.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Mulish-Regular';\n  font-style: normal;\n  font-weight: 400;\n  src: url('./Mulish-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Mulish-SemiBold';\n  font-style: normal;\n  font-weight: 600;\n  src: url('./Mulish-SemiBold.ttf') format('truetype');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
