import { FC, useState } from 'react';
import { hooks } from '../hooks';
import { custom } from '../custom';
import { svg } from '../assets/svg';
import { components } from '../components';
import { theme } from '../constants';
import api from '../api/api';

export const NewPassword: FC = () => {
  const navigate = hooks.useAppNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const token = window.location.search.slice(1); 
      console.log(token)

      const response = await api.put(`/users/resetPassword/${token}`, { password });

      if (response.status === 200) {
        navigate('/ForgotPasswordSentEmail');
      } else {
        setErrorMessage('Failed to reset password');
      }
    } catch (error : any) {
      setErrorMessage('An error occurred while resetting the password');
      console.error(error.response.data)
    }
  };

  const renderHeader = () => {
    return <components.Header title='Reset password' goBack={true} />;
  };

  const renderContent = () => {
    return (
      <div style={{ padding: '30px 20px 20px 20px' }}>
        <p
          style={{
            margin: 0,
            marginBottom: 40,
            color: theme.colors.textColor,
            fontSize: '20px'
          }}
        >
          Enter new password and confirm.
        </p>

        {errorMessage && (
          <p style={{ color: 'red', marginBottom: 20 }}>{errorMessage}</p>
        )}

        <custom.InputField
          clickable={true}
          label='Password'
          name='password'
          placeholder='Password'
          type={showPassword ? 'text' : 'password'}
          icon={
            <span
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: 'pointer' }}
            >
              {showPassword ? <svg.EyeSvg /> : <svg.EyeOffSvg />}
            </span>
          }
          containerStyle={{ marginBottom: 20 }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <custom.InputField
          clickable={true}
          label='Confirm Password'
          name='confirmPassword'
          placeholder='Confirm Password'
          type={showConfirmPassword ? 'text' : 'password'}
          icon={
            <span
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{ cursor: 'pointer' }}
            >
              {showConfirmPassword ? <svg.EyeSvg /> : <svg.EyeOffSvg />}
            </span>
          }
          containerStyle={{ marginBottom: 20 }}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <components.Button
          title='Change Password'
          onClick={handleChangePassword}
        />
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
