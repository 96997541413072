import { FC } from 'react';

export const EyeSvg: FC = () => {
  return (
    <svg width={16} height={16} fill='none'>
      <g stroke='#193364' strokeLinecap='round' strokeLinejoin='round'>
        {/* Outline of the eye */}
        <path d='M1 8s2.667-5.333 7-5.333S15 8 15 8s-2.667 5.333-7 5.333S1 8 1 8Z' />
        {/* Pupil of the eye */}
        <circle cx='8' cy='8' r='2.5' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};
