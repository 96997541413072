import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { custom } from '../custom';
import { theme } from '../constants';
import { components } from '../components';
import api from '../api/api';
import { useUser } from '../contexts/UserContext';
import { Loader } from '../components/Loader';
import Swal from 'sweetalert2';

export const Prescription: React.FC = () => {
  const navigate = useNavigate();
  const [prescriptionImage, setPrescriptionImage] = useState<File | null>(null);
  const {user} = useUser();
  const [note, setNote] = useState<string>('');
  const [streetNo, setStreetNo] = useState<string>(user?.address?.streetNo || '');
  const [streetName, setStreetName] = useState<string>(user?.address?.streetName || '');
  const [suburb, setSuburb] = useState<string>(user?.address?.suburb || '');
  const [estate, setEstate] = useState<string>(user?.address?.estate || '');
  const [forWho, setForWho] = useState<string>(user?.familyMembers[0] || '');
  const [fileName, setFileName] = useState<string>(''); 
  const [loading, setLoading] = useState<boolean>(false); 

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
  
      const fileType = file.type.split('/')[0]; 
      if (fileType !== 'image') {
        alert('Please upload an image file.');
        return;
      }
  
      if (file.size > 5 * 1024 * 1024) {
        alert('File size should not exceed 5 MB.');
        return;
      }
  
      setPrescriptionImage(file);
      setFileName(file.name);
    }
  };
  

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const agent = user;
  
    const formData = new FormData();
    if (prescriptionImage) {
      formData.append('prescriptionImage', prescriptionImage);
    }
    formData.append('userId', agent ? agent._id : '');
    formData.append('userName', agent ? agent.userName : '');
    formData.append('note', note);
    formData.append('forWho', forWho);
    formData.append('streetNo', streetNo);
    formData.append('streetName', streetName);
    formData.append('suburb', suburb);
    formData.append('estate', estate);
  
    try {
      const response = await api.post('/prescription', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${agent ? agent.token : null}`,
        },
      });
  
      if (response.status === 201) {
        console.log('Order successful');
        navigate('/OrderSuccessful');
      }
    } catch (error: any) {
      const errorMessage =  error.response.data.error || 'An error occurred while submitting the form.';
      
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: errorMessage,
      });
  
      console.error('Upload Error: ', error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div style={{ padding: '20px' }}>
      <components.Header goBack={true} title='Prescription Form' />
      <div style={{ marginBottom: '20px', display:'flex', flexDirection:'column', gap:'15px' }}>
        <div style={{marginLeft:'30px', marginTop: '20px'}}>
        <label style={{ color: theme.colors.textColor, fontSize: '16px', marginBottom:'10px' }}>
          Prescription Image
        </label>
        <div>
          <input
            type='file'
            accept='image/*' 
            capture='environment'
            onChange={handleFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
            id='upload'
          />
          <label htmlFor='upload' style={{ display: 'block', cursor: 'pointer' }}>
            <components.Button title='Upload' style={{ marginBottom: 10, width:'20%', height:'35px ' }} onClick={handleUploadButtonClick} />
          </label>
          {fileName && (  
            <p style={{ color: theme.colors.textColor, fontSize: '14px', marginTop: '10px' }}>
              Selected file: {fileName}
            </p>
          )}
        </div>
        </div>
        <custom.InputField
          name='Note'
          value={note}
          label='Note'
          placeholder='Write any related description'
          onChange={(e) => setNote(e.target.value)}
          containerStyle={{ marginBottom: '10px' }}
        />
        <custom.InputField
          type='Select'
          name='for'
          value={forWho}
          label='For'
          placeholder='Yourself, Mother, Father, etc.'
          onChange={(e) => setForWho(e.target.value)}
          containerStyle={{ marginBottom: '10px' }}
          options={user?.familyMembers}
        />
        {/* <h3 style={{ color: theme.colors.darkBlue, fontSize: '18px' }}>Address</h3> */}
        <custom.InputField
          name='StreetNo'
          value={streetNo}
          type='Number'
          label='Pincode'
          placeholder='Pincode'
          onChange={(e) => setStreetNo(e.target.value)}
          containerStyle={{ marginBottom: '10px' }}
        />
        <custom.InputField
          name='Street Name'
          value={streetName}
          label='House No., Building Name, Landmark'
          placeholder='House No., Building Name, Landmark'
          onChange={(e) => setStreetName(e.target.value)}
          containerStyle={{ marginBottom: '10px' }}
        />
        <custom.InputField
          name='Suburb'
          value={suburb}
          label='City'
          placeholder='City'
          onChange={(e) => setSuburb(e.target.value)}
          containerStyle={{ marginBottom: '10px' }}
        />
        <custom.InputField
          name='Estate'
          value={estate}
          label='State'
          placeholder='State'
          onChange={(e) => setEstate(e.target.value)}
          containerStyle={{ marginBottom: '20px' }}
        />
        {loading ? (
          <Loader /> 
        ) : (
          <components.Button title="Submit" onClick={handleSubmit} /> 
        )}      
      </div>
    </div>
  );
};
