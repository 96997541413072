import {useEffect, useState, FC} from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import {theme} from '../constants';
import {components} from '../components';
import {useUser} from '../contexts/UserContext';
import api from '../api/api';
import { Button } from 'react-bootstrap';

export const NewUsers: FC = () => {
  const [loading, setLoading] = useState(false);
  const [newUsers, setNewUsers] = useState<any[]>([]);
  const {user} = useUser();


  useEffect(()=>{
    getUser()
  },[]);

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/users/`, {
        headers: {
          Authorization: `Bearer ${user ? user.token : null}`,
        },
      });
      console.log(response.data);
      setNewUsers(response.data);
    } catch (error: any) {
      console.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };


  const updateUser = async (userId :any) => {
    try {
        const response = await api.patch(
          `/users/verify`,{
            userId
          },
          {
            headers: {
              Authorization: `Bearer ${user ? user.token : null}`,
            },
          },
        );

        console.log(response.data);
        getUser();
    } catch (error: any) {
      console.error(error.response.data.message);
    }
  };

  const renderHeader = () => {
    return <components.Header goBack={true} title='New Users' />;
  };

  const renderContent = (): JSX.Element => {
    if (loading) return <components.Loader />;

    return (
      <div style={{padding: '10px 0 20px 0'}}>
  
        <Accordion.Root type='single' collapsible={true}>
          {newUsers.length > 0 ? (
            newUsers.reverse().map((user, index) => {
              const userId = `#${user._id.slice(-6)}`;
            
              return (
                <Accordion.Item key={index} value={user._id}>
                  <Accordion.Trigger
                    style={{
                      flexDirection: 'column',
                      width: '100%',
                      display: 'flex',
                      padding: '10px 20px 18px 20px',
                      borderBottom: '4px solid #E8EFF4',
                      position:'relative',
                      gap: '10px'
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 7,
                      }}
                    >
                      <h5
                        style={{
                          margin: 0,
                          ...theme.fonts.Mulish_600SemiBold,
                          fontSize: 16,
                          color: theme.colors.mainColor,
                          lineHeight: 1.2,
                        }}
                      >
                        {user.userName}{' '}
                        <span style={{fontSize: '12px'}}>({userId})</span>
                      </h5>
                      <Button
                        style={{
                          ...theme.fonts.Mulish_400Regular,
                          fontSize: 14,
                          textTransform: 'capitalize',
                          lineHeight: 1.5,
                          backgroundColor: '#ff6900',
                          border: 'none',
                          position: 'absolute',
                          right: '18px',
                          top: '10px'
                        }}
                        onClick={()=>updateUser(user._id)}
                      >
                        verify
                      </Button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          ...theme.fonts.Mulish_400Regular,
                          fontSize: 12,
                          color: theme.colors.textColor,
                          lineHeight: 1.5,
                        }}
                      >
                        {new Date(user.createdAt).toLocaleString('en-US', {
                          month: 'short',
                          day: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                      <span
                        style={{
                          ...theme.fonts.Mulish_700Bold,
                          fontSize: 12,
                          color: theme.colors.mainColor,
                          lineHeight: 1.5,
                        }}
                      >
                        Role: {user.role}
                      </span>
                    </div>
                  </Accordion.Trigger>
                  <Accordion.Content
                    style={{
                      backgroundColor: '#F8FAFC',
                      borderBottom: '4px solid #E8EFF4',
                      padding: '16px 24px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                      <div style={{marginBottom: '12px'}}>
                        <div style={{margin: 0, color: '#333'}}>
                          <div style={{marginBottom: '12px'}}>
                            <strong>Full Name:</strong> {user.fullName}
                          </div>
                          <div style={{marginBottom: '12px'}}>
                            <strong>Email:</strong> {user.email}
                          </div>
                          <div style={{marginBottom: '12px'}}>
                            <strong>Aadhar No.:</strong> {user.aadharNumber}
                          </div>
                        </div>
                      </div>
                    </Accordion.Content>
                </Accordion.Item>
              );
            })
          ) : (
            <div style={{padding: '50px 20px 20px 20px'}}>
              <img
                src={require('../assets/icons/08.png')}
                alt='order successful'
                style={{
                  width: 225.18,
                  display: 'block',
                  margin: '0 auto',
                  marginBottom: 14,
                }}
              />
              <h2
                style={{
                  margin: 0,
                  textAlign: 'center',
                  ...theme.fonts.Mulish_700Bold,
                  fontSize: 22,
                  lineHeight: 1.2,
                  color: theme.colors.mainColor,
                  marginBottom: 14,
                  textTransform: 'capitalize',
                  whiteSpace: 'pre-line',
                }}
              >
                No Users Found!
              </h2>
            </div>
          )}
        </Accordion.Root>
      </div>
    );
  };

  


  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
